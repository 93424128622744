import React from "react";
import Layout from "components/layout";
import { withI18next } from "lib/withI18next";

import { page } from "components/page";
import AdvanceSearchBlock from "components/search/AdvanceSearchBlock";

@withI18next(["common"])
@page
class AdvanceSearch extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    let { t, data } = this.props;
    return (
      <Layout {...this.props}>
        <a class="accesskey" href="#aC" id="aC" accesskey="C" title="主要內容區">:::</a>
        <div className="main mp" id="center">
          <AdvanceSearchBlock searchType="resource" />
        </div>
      </Layout>
    );
  }
}

export default AdvanceSearch;
